import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroError from "../components/General/HeroError";
import Footer from "../components/Context/Footer";

const NotFoundPage = () => {
    const theme = "dark";

    return (
        <Layout>
            <SEO title="Please upgrade your browser" />
            <Header theme={theme} hideNav={true} />
            <HeroError
                title={"Please upgrade your browser"}
                tagline={"<p>It looks like you are using an outdated browser which isn't supported by our site. Please visit <a href='https://browsehappy.com' target='_blank' rel='noopener noreferrer'>BrowseHappy</a> to upgrade to an up to date browser.</p>"}
                noFlex={true}
                shortenedPaddingTop={true}
                theme={theme}
            />
            <Footer theme={theme} hideNav={true} noFlex={true} />
        </Layout>
    );
};


export default NotFoundPage;
